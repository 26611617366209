import { Colors } from "../types"

export const getTheme = {
  typography: {
    fontFamily: [
      "figtree",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: { main: Colors.purple900 },
    info: { main: Colors.white },
    success: { main: Colors.purple900 },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "inherit",
            cursor: "pointer",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          ":hover": {
            cursor: "pointer",
          },
        },
      },
    },
  },
}
