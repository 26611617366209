import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { CheckCircle } from "@phosphor-icons/react"
import React, { useCallback, useState } from "react"
import { useAppSelector } from "../redux"
import { getStripePortalUrl } from "../services"
import { Colors, StripeProduct } from "../types"
import { getCurrencySymbol } from "../utils"
import { MainButton } from "./buttons"

interface ProductBoxProps {
  product: StripeProduct
  sx?: React.CSSProperties
}

export function ProductBox({ product: productDetails, sx }: ProductBoxProps) {
  const [processing, setProcessing] = useState<boolean>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const price = productDetails.prices?.[0]
  const recurring = price?.recurring

  const user = useAppSelector((state) => state.app.user)
  const currentPlan = user?.subscriptionPlan || "Free"

  const handleSettingsClick = useCallback(async () => {
    try {
      setProcessing(true)
      if (!recurring) {
        window.open(
          "https://calendly.com/awais-beyondpresence/sales_discovery_call",
          "_blank",
        )
      } else {
        const stripePortalUrl = await getStripePortalUrl(
          user.uid,
          window.location.href,
        )
        window.open(stripePortalUrl, "_self")
      }
    } catch (error) {
      console.error("Failed to get Stripe portal URL:", error)
    } finally {
      setProcessing(false)
    }
  }, [user])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: isMobile ? "100%" : "24%",
        height: "100%",
        padding: "1rem",
        backgroundColor: Colors.grey100,
        borderRadius: "0.5rem",
        border: `1px solid ${Colors.grey300}`,
        ...sx,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        flexWrap="wrap"
      >
        <Typography sx={{ fontWeight: "bold", mr: "0.5rem" }} variant="h6">
          {productDetails.name}
        </Typography>
      </Box>

      <Typography
        sx={{ color: Colors.grey800, minHeight: "50px", mt: 1 }}
        variant="body2"
      >
        {productDetails.description}
      </Typography>
      {recurring && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          sx={{ mt: "1rem" }}
        >
          <Typography sx={{ fontWeight: "bold" }} variant="h4">
            {getCurrencySymbol(price?.currency)}
            {price?.unit_amount / 100}
          </Typography>

          <Box sx={{ ml: "0.5rem" }}>
            <Typography
              sx={{ fontSize: "0.8rem", color: Colors.grey800 }}
              variant="body2"
            >
              per
            </Typography>
            <Typography
              sx={{ fontSize: "0.8rem", color: Colors.grey800 }}
              variant="body2"
            >
              month
            </Typography>
          </Box>
        </Box>
      )}
      <MainButton
        style={{
          width: "100%",
          marginTop: !recurring ? "4.5rem" : "1rem",
        }}
        text={
          currentPlan === productDetails.name
            ? "Current Plan"
            : !recurring
              ? "Schedule a call"
              : "Subscribe"
        }
        disabled={currentPlan === productDetails.name}
        white={currentPlan === productDetails.name}
        onClick={handleSettingsClick}
        loading={processing}
      />
      <Box sx={{ mt: "1rem" }}>
        <Typography variant="body2">This includes:</Typography>
        <Box sx={{ mt: "0.5rem" }}>
          {productDetails.marketing_features.map((feature) => (
            <Box
              key={feature.name}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mt={"0.1rem"}
            >
              <CheckCircle color={Colors.almostBlack} size={16} />
              <Typography sx={{ ml: "0.5rem" }} variant="body2">
                {feature.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
