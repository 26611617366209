import { User } from "firebase/auth"
import mixpanel from "mixpanel-browser"
import { isDev, isLocalDev, isStaging } from "./general"

export const initializeMixpanel = () => {
  if (!isLocalDev) {
    mixpanel.init(
      isDev
        ? "e46f49d9d64303e14eadb47197eb3929"
        : isStaging
          ? "b7d26b6ad8f29efdf90f593edd93b225"
          : "2c7730f569fa81a3a26dfffeac4d6a68",
      {
        debug: false,
        persistence: "localStorage",
      },
    )
  }
}

export const setUserOnMixpanel = (user: User) => {
  if (!isLocalDev) {
    mixpanel.identify(user.uid)
    mixpanel.people.set({
      $name: user.displayName || "",
      $email: user.email,
    })
  }
}

export const resetMixpanel = () => {
  if (!isLocalDev) {
    mixpanel.reset()
  }
}
