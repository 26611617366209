export enum FeedbackEntity {
  AVATAR = "avatar",
  VIDEO = "video",
  AGENT = "agent",
  OUTREACH_CAMPAIGN = "campaign",
}

export interface Feedback {
  id: string
  createdAt: string
  updatedAt: string
  creatorId: string
  rating: number
  feedback: string
  contactConsent: boolean
  feedbackTopic: string
  entityType: FeedbackEntity | null
  entityId: string | null
}
