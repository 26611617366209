import { Fab } from "@mui/material"
import { type Icon } from "@phosphor-icons/react"
import React from "react"
import { Colors } from "../../types"

interface FloatingActionButtonProps {
  onClick: () => void
  Icon: Icon
}

export function FloatingActionButton({
  onClick,
  Icon,
}: FloatingActionButtonProps) {
  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
        backgroundColor: Colors.purple900,
        "&:hover": {
          backgroundColor: Colors.purple900,
        },
      }}
      onClick={onClick}
    >
      <Icon color={Colors.white} size={24} />
    </Fab>
  )
}
