import * as Yup from "yup"

export const AuthSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character",
    ),
})
export type TAuthSchema = Yup.InferType<typeof AuthSchema>
export const AuthInitialValues: TAuthSchema = {
  email: "",
  password: "",
}

export const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .min(6, "Password must be at least 6 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character",
    ),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
})
export type TNewPasswordSchema = Yup.InferType<typeof NewPasswordSchema>
export const NewPasswordInitialValues: TNewPasswordSchema = {
  password: "",
  confirmPassword: "",
}
