import * as Sentry from "@sentry/react"
import { isLocalDev } from "./general"

export const initializeSentry = () => {
  if (!isLocalDev) {
    Sentry.init({
      dsn: "https://b1e296da85819060a2b7bd25af941a36@o4507310462140416.ingest.de.sentry.io/4507469951533136",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.01, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/app\.bey\.chat/,
        /^https:\/\/api\.bey\.chat/,
        /^https:\/\/api-dev\.bey\.chat/,
        /^https:\/\/app-dev\.bey\.chat/,
      ],
      // Session Replay
      replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
