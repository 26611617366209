import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../redux"
import { AppActions } from "../redux/appSlice"
import { checkCurrentUsage } from "../services"

export function useGetSubscription() {
  const user = useAppSelector((state) => state.app.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const checkUserSubscription = async () => {
      dispatch(AppActions.updateShowStripeModal(false))

      if (
        user?.uid &&
        (user.subscriptionPlan !== undefined ||
          user.subscriptionState !== undefined)
      ) {
        try {
          const usageDetails = await checkCurrentUsage(user.uid)
          dispatch(AppActions.updateUsageDetails(usageDetails))
        } catch (error) {
          console.error("Error checking user subscription:", error)
        }
      }
    }

    checkUserSubscription()
  }, [user, dispatch])
}
