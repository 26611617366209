import React, { lazy, Suspense } from "react"
import { FullScreenLoader, PasswordDialog } from "../../components"
import { DisableMobile } from "../../components/ui/disableMobile"
import {
  AgentScreen,
  AuthScreen as AuthScreenEnum,
  AvatarScreen,
  ConversationScreen,
  OutreachCampaignScreen,
  Screen,
  SettingsScreen as SettingsScreenEnum,
  User,
  VideoScreen,
} from "../../types"
import ErrorPage from "../error-page"
import { TextToVideoScreen } from "../routes/TextToVideo/VideosScreen"

// Constants
const PASSWORD = "beyondpresence24"
const IMPORT_ERROR_MESSAGES = [
  "Failed to fetch dynamically imported module",
  "Importing a module script failed",
]

// Lazy loading helper
const lazyLoad = (importFunc: () => Promise<any>) =>
  lazy(() =>
    importFunc()
      .then((module) => ({
        default: module.default || module[Object.keys(module)[0]],
      }))
      .catch(errorHandler),
  )

// Lazy loaded components
const LoginScreen = lazyLoad(() => import("../routes/LoginScreen/LoginScreen"))
const OverviewScreen = lazyLoad(() => import("./Auth/OverviewScreen"))
const AuthScreen = lazyLoad(() => import("./Auth/AuthScreen"))
const ResetPasswordScreen = lazyLoad(() => import("./Auth/ResetPassword"))
const NewPasswordScreen = lazyLoad(() => import("./Auth/NewPassword"))

const AvatarsScreen = lazyLoad(() => import("../routes/Avatars/AvatarsScreen"))
const CreateAvatarScreen = lazyLoad(
  () => import("../routes/Avatars/CreateAvatar"),
)
const CreateCampaignScreen = lazyLoad(
  () => import("../routes/OutreachCampaigns/CreateCampaign"),
)

const PreviewScreen = lazyLoad(
  () => import("../routes/OutreachCampaigns/PreviewScreen"),
)
const CampaignFinalResultScreen = lazyLoad(
  () => import("../routes/OutreachCampaigns/FinalResult"),
)
const GenerateAvatarScreen = lazyLoad(
  () => import("./Avatars/GenerateAvatarScreen"),
)
const SelectAvatarScreenNew = lazyLoad(() => import("./Shared/selectAvatar"))
const OutreachVideosScreen = lazyLoad(
  () => import("../routes/OutreachCampaigns/OutreachVideos"),
)
const MyAgentsScreen = lazyLoad(() => import("./Agents/myAgents"))
const MyAgentsAgentInfoScreen = lazyLoad(() => import("./Agents/agentsInfo"))
const MyAgentsTrainAgentScreen = lazyLoad(() => import("./Agents/trainAgent"))
const MyAgentsFinalResultScreen = lazyLoad(() => import("./Agents/finalResult"))
const MyAgentsAgentDetailsScreen = lazyLoad(
  () => import("./Agents/agentDetails"),
)

const CreateVideoMessageScreen = lazyLoad(
  () => import("../routes/TextToVideo/CreateVideoMessage"),
)
const FinalResultScreen = lazyLoad(() => import("./TextToVideo/FinalResult"))

const SettingsScreen = lazyLoad(() => import("./Settings/Settings"))
const SubscriptionScreen = lazyLoad(() => import("./Settings/Subscription"))

const AllConversations = lazyLoad(
  () => import("./Conversations/AllConversations"),
)
const ConversationDetails = lazyLoad(
  () => import("./Shared/ConversationDetails"),
)
const KnowledgeBase = lazyLoad(() => import("./KnowledgeBase/KnowledgeBase"))

const errorHandler = (e: any) => {
  if (IMPORT_ERROR_MESSAGES.some((msg) => e.message.includes(msg))) {
    window.location.reload()
    return {} as any
  } else {
    throw e
  }
}

// Route generation helper
const createSuspenseRoute = (
  Component: React.LazyExoticComponent<any>,
  loader?: any,
) => ({
  element: (
    <Suspense fallback={<FullScreenLoader />}>
      <Component />
    </Suspense>
  ),
  loader,
})

export const getRouterData = (user: User, isMobile: boolean) => {
  if (isMobile) {
    return [
      {
        path: "/",
        element: <DisableMobile />,
        errorElement: <ErrorPage />,
      },
    ]
  }

  const commonRoutes = [
    {
      path: "/",
      element: <TextToVideoScreen />,
      errorElement: <ErrorPage />,
    },
    {
      path: Screen.outreachCampaigns,
      ...createSuspenseRoute(OutreachVideosScreen),
    },
    {
      path: `${Screen.outreachCampaigns}/${OutreachCampaignScreen.selectCampaignAvatar}`,
      ...createSuspenseRoute(SelectAvatarScreenNew),
    },
    {
      path: `${Screen.outreachCampaigns}/${OutreachCampaignScreen.preview}`,
      ...createSuspenseRoute(PreviewScreen),
    },
    {
      path: `${Screen.outreachCampaigns}/${OutreachCampaignScreen.finalResult}`,
      ...createSuspenseRoute(CampaignFinalResultScreen),
    },
    {
      path: `${Screen.outreachCampaigns}/${OutreachCampaignScreen.createCampaignMessage}`,
      ...createSuspenseRoute(CreateCampaignScreen),
    },
    {
      path: Screen.avatars,
      ...createSuspenseRoute(AvatarsScreen),
    },
    {
      path: `${Screen.avatars}/${AvatarScreen.createAvatar}`,
      ...createSuspenseRoute(CreateAvatarScreen),
    },
    {
      path: `${Screen.avatars}/${AvatarScreen.generateAvatar}`,
      ...createSuspenseRoute(GenerateAvatarScreen),
    },
    {
      path: `${Screen.videos}/${VideoScreen.selectVideoAvatar}`,
      ...createSuspenseRoute(SelectAvatarScreenNew),
    },
    {
      path: `${Screen.videos}/${VideoScreen.createVideoMessage}`,
      ...createSuspenseRoute(CreateVideoMessageScreen),
    },
    {
      path: `${Screen.videos}/${VideoScreen.finalResult}`,
      ...createSuspenseRoute(FinalResultScreen),
    },
    {
      path: `${Screen.myAgents}`,
      ...createSuspenseRoute(MyAgentsScreen),
    },
    {
      path: `${Screen.myAgents}/${AgentScreen.selectAvatar}`,
      ...createSuspenseRoute(SelectAvatarScreenNew),
    },
    {
      path: `${Screen.myAgents}/${AgentScreen.agentsInfo}`,
      ...createSuspenseRoute(MyAgentsAgentInfoScreen),
    },
    {
      path: `${Screen.myAgents}/${AgentScreen.trainAgent}`,
      ...createSuspenseRoute(MyAgentsTrainAgentScreen),
    },
    {
      path: `${Screen.myAgents}/${AgentScreen.finalResult}`,
      ...createSuspenseRoute(MyAgentsFinalResultScreen),
    },
    {
      path: `${Screen.myAgents}/${AgentScreen.agentDetails}`,
      ...createSuspenseRoute(MyAgentsAgentDetailsScreen),
    },
    {
      path: `${Screen.myAgents}/${ConversationScreen.conversationDetails}`,
      ...createSuspenseRoute(ConversationDetails),
    },
    {
      path: `${Screen.settings}`,
      ...createSuspenseRoute(SettingsScreen),
    },
    {
      path: `${Screen.settings}/${SettingsScreenEnum.subscription}`,
      ...createSuspenseRoute(SubscriptionScreen),
    },
    {
      path: `${Screen.conversations}`,
      ...createSuspenseRoute(AllConversations),
    },
    {
      path: `${Screen.conversations}/${ConversationScreen.conversationDetails}`,
      ...createSuspenseRoute(ConversationDetails),
    },
    {
      path: `${Screen.knowledgeBase}`,
      ...createSuspenseRoute(KnowledgeBase),
    },
  ]

  const authRoutes = [
    {
      path: "/",
      ...createSuspenseRoute(OverviewScreen),
    },
    {
      path: `${Screen.auth}`,
      ...createSuspenseRoute(AuthScreen),
    },
    {
      path: `${Screen.auth}/${AuthScreenEnum.resetPassword}`,
      ...createSuspenseRoute(ResetPasswordScreen),
    },
    {
      path: `${Screen.auth}/${AuthScreenEnum.newPassword}`,
      ...createSuspenseRoute(NewPasswordScreen),
    },
    {
      path: `${Screen.enterPassword}`,
      element: <PasswordDialog />,
      errorElement: <ErrorPage />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]

  return user ? commonRoutes : authRoutes
}
