import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../redux"
import { AppActions } from "../../redux/appSlice"

export function PasswordDialog() {
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState("")

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    dispatch(AppActions.updateUserEnteredPassword(password))
    navigate(`/`)
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
      }}
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        Unlock Beyond Presence
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleClose()
            navigate(`/`)
          },
        }}
      >
        <DialogTitle>Unlock Beyond Presence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the password you have received from the team.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="password"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
