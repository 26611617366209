import { Box, CardActionArea, CardContent, Typography } from "@mui/material"
import { PlusSquare } from "@phosphor-icons/react"
import React from "react"
import { Colors } from "../../types"

interface EmptyCardProps {
  onClick: () => void
  mainText: string
}

export const EmptyCard: React.FC<EmptyCardProps> = ({ onClick, mainText }) => {
  return (
    <Box
      sx={{
        height: "15rem",
        display: "flex",
        justifyContent: "center",
        aspectRatio: "1/1",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: Colors.purple100,
        borderRadius: "10px",
        padding: "1rem",
      }}
    >
      <CardActionArea onClick={onClick} sx={{ height: "100%" }}>
        <CardContent
          sx={{
            textAlign: "center",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ mt: 2, color: Colors.purple900 }}>
            {mainText}
          </Typography>
          <PlusSquare size={50} color={Colors.purple900} />
        </CardContent>
      </CardActionArea>
    </Box>
  )
}
