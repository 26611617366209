import { Agent } from "./Agent"
import { Avatar } from "./Avatar"
import { OutreachCampaign } from "./OutreachCampaign"
import { AvatarScreen, OutreachCampaignScreen, VideoScreen } from "./Screen"
import { Video } from "./Video"

export enum SnackbarType {
  success = "success",
  error = "error",
}

export interface SnackbarProps {
  text: string
  open: boolean
  severity?: SnackbarType
}

export const initialSnackbarProps: SnackbarProps = {
  open: false,
  severity: SnackbarType.error,
  text: "",
}

export enum Colors {
  grey100 = "#F7F8FA",
  black = "#000000",
  white = "#FFFFFF",
  grey200 = "#F0EEF5",
  sideBarDark = "#4E405A",
  sideBarDarkOpacity = "#4E405A50",
  purple900 = "#4019B6",
  purple200 = "#ECE6FF",
  purple100 = "#F5F2FF",
  grey300 = "#E7E6ED",
  almostBlack = "#220738",
  grey500 = "#A69FAC",
  grey800 = "#848484",
  grey900 = "#666666",
  red500 = "#F00000",
}

export interface ImageListItemProps
  extends Partial<Avatar>,
    Partial<Video>,
    Partial<Agent> {
  selected: boolean
}

export enum ScreenModes {
  outreachCampaign = "outreachCampaign",
  video = "video",
  avatar = "avatar",
}

export const getScreenModeFromScreen = (
  screen: OutreachCampaignScreen | VideoScreen | AvatarScreen,
): ScreenModes => {
  if (Object.keys(OutreachCampaignScreen).includes(screen)) {
    return ScreenModes.outreachCampaign
  }
  if (Object.keys(AvatarScreen).includes(screen)) {
    return ScreenModes.avatar
  }
  if (Object.keys(VideoScreen).includes(screen)) {
    return ScreenModes.video
  }
}

export const getScreenModeFromMatchedElement = (
  matchedElement: Video | OutreachCampaign | Avatar,
): ScreenModes => {
  if (isTypeofVideo(matchedElement)) {
    return ScreenModes.video
  }
  if (isTypeofOutreachCampaign(matchedElement)) {
    return ScreenModes.outreachCampaign
  }
  return ScreenModes.avatar
}

export enum SidebarTextValues {
  avatars = "Avatars",
  outreachCampaigns = "Outreach Videos",
  textToVideo = "Text-to-video",
  myAgents = "My Agents",
  knowledgeBase = "Knowledge base",
  conversations = "Conversations",
  analytics = "Analytics",
  helpUsImprove = "Help us improve",
  settings = "Payment Settings",
}

export const isTypeofVideo = (
  e: OutreachCampaign | Video | Avatar | Agent,
): e is Video => {
  return (e as Video)?.script != null
}

export const isTypeofOutreachCampaign = (
  e: OutreachCampaign | Avatar | Agent,
): e is OutreachCampaign => {
  return (e as OutreachCampaign)?.avatarId != null
}

export const isTypeofAgent = (e: Agent | Avatar): e is Agent => {
  return (e as Agent)?.name != null
}

export const EntityTypes = {
  video: "video",
  avatar: "avatar",
  campaign: "campaign",
  agent: "agent",
}
