import { Box, Stack } from "@mui/material"
import React from "react"
import { Header, Sidebar } from "../../components"
import { Colors } from "../../types"

export function MainLayout({
  children,
  showTopbar = true,
  onBackPress,
  pb = "1.5rem",
}: {
  children: React.ReactNode
  showTopbar?: boolean
  onBackPress?: () => void
  pb?: string
}) {
  return (
    <Box sx={{ backgroundColor: Colors.white }}>
      <Stack direction="row" spacing={0}>
        <Box
          sx={{
            width: "17.5rem",
            height: "100dvh",
            pt: "1.5rem",
            pb: "1.5rem",
            pl: "1rem",
            pr: "1rem",
          }}
        >
          <Sidebar />
        </Box>
        <Box
          sx={{
            backgroundColor: Colors.grey100,
            height: "100dvh",
            width: "calc(100dvw - 17.5rem)",
            pt: "1.5rem",
            pb: pb,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Header onBackPress={onBackPress} showTopbar={showTopbar} />
          {children}
        </Box>
      </Stack>
    </Box>
  )
}
