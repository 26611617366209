import * as Sentry from "@sentry/react"
import { signOut } from "firebase/auth"
import { firebaseAuth } from "../utils"

export const signUserOut = async () => {
  await signOut(firebaseAuth).catch((error) => {
    Sentry.captureException(error)
    console.error(error)
  })
}

export const isUserAdmin = async (): Promise<boolean> => {
  const tokenResult = await firebaseAuth.currentUser?.getIdTokenResult(true)
  if (!tokenResult?.claims.admin) {
    return false
  }
  return tokenResult.claims.admin as boolean
}
