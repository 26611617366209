import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { isDev, isStaging } from "./general"

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: "bey-dev.firebaseapp.com",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : isStaging
    ? {
        apiKey: "AIzaSyC9TAzKVcWIruehblFd-4gI7MpPyFM2I7Q",
        authDomain: "bey-staging.firebaseapp.com",
        projectId: "bey-staging",
        storageBucket: "bey-staging.firebasestorage.app",
        messagingSenderId: "418945392850",
        appId: "1:418945392850:web:5e7c4d06e9ffaf5bbb8d0b",
        measurementId: "G-2CPNB48FMS",
      }
    : {
        apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
        authDomain: "bey-prod.firebaseapp.com",
        projectId: "bey-prod",
        storageBucket: "bey-prod.appspot.com",
        messagingSenderId: "352012720018",
        appId: "1:352012720018:web:27a46e753a2e56983f534b",
        measurementId: "G-BN8E3VD31V",
      }

const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseDb = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
firebaseAuth.useDeviceLanguage()
