import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import "react-toastify/dist/ReactToastify.css"
import { PersistGate } from "redux-persist/integration/react"
import App from "./app/App"
import "./index.css"
import { persistor, store } from "./redux"

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
