import { Box, Button, Typography } from "@mui/material"
import {
  Book,
  ChartBar,
  ChatDots,
  ChatTeardropDots,
  FileVideo,
  Headset,
  Money,
  UserFocus,
  UsersFour,
} from "@phosphor-icons/react"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Colors, SidebarTextValues } from "../../../types"

export function SidebarText({
  text,
  href,
  disabled = false,
  onClick,
}: {
  text: string
  href: string
  disabled?: boolean
  onClick?: () => void
}) {
  const location = useLocation()
  const path = location.pathname
  const highlighted =
    href === "/"
      ? path === "/" || path.startsWith("/videos")
      : path.startsWith(href)

  const getStartIcon = () => {
    const sx = {
      color: disabled
        ? Colors.sideBarDarkOpacity
        : highlighted
          ? Colors.purple900
          : Colors.sideBarDark,
    }
    switch (text) {
      case SidebarTextValues.avatars:
        return <UserFocus color={sx.color} />
      case SidebarTextValues.outreachCampaigns:
        return <UsersFour color={sx.color} />
      case SidebarTextValues.textToVideo:
        return <FileVideo color={sx.color} />
      case SidebarTextValues.myAgents:
        return <Headset color={sx.color} />
      case SidebarTextValues.knowledgeBase:
        return <Book color={sx.color} />
      case SidebarTextValues.conversations:
        return <ChatTeardropDots color={sx.color} />
      case SidebarTextValues.analytics:
        return <ChartBar color={sx.color} />
      case SidebarTextValues.helpUsImprove:
        return <ChatDots color={sx.color} />
      case SidebarTextValues.settings:
        return <Money color={sx.color} />
    }
  }

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    onClick?.()
  }

  const buttonContent = (
    <Button
      startIcon={getStartIcon()}
      disabled={disabled}
      onClick={handleClick}
      sx={{
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: highlighted ? Colors.purple200 : "transparent",
        borderRadius: "0.5rem",
      }}
    >
      <Typography
        textTransform="uppercase"
        fontSize="0.875rem"
        fontWeight="500"
        lineHeight="120%"
        color={
          disabled
            ? Colors.sideBarDarkOpacity
            : highlighted
              ? Colors.purple900
              : Colors.sideBarDark
        }
      >
        {text}
      </Typography>
    </Button>
  )

  return (
    <Box
      sx={{
        pl: "0.75rem",
        pr: "0.75rem",
        pt: "0.5rem",
        pb: "0.5rem",
        width: "100%",
      }}
    >
      {disabled ? buttonContent : <Link to={href}>{buttonContent}</Link>}
    </Box>
  )
}
