import { ChatDots } from "@phosphor-icons/react"
import React from "react"
import { useAppDispatch } from "../../redux"
import { AppActions } from "../../redux/appSlice"
import { FloatingActionButton } from "./floatingActionButton"

export function FeedbackFAB() {
  const dispatch = useAppDispatch()

  const handleOpenFeedbackModal = () => {
    dispatch(
      AppActions.updateFeedbackModalState({
        visibility: "full",
        title: "How happy are you with using our product?",
        entityId: null,
        entityType: null,
      }),
    )
  }

  return (
    <FloatingActionButton Icon={ChatDots} onClick={handleOpenFeedbackModal} />
  )
}
