import { logEvent } from "firebase/analytics"
import mixpanel from "mixpanel-browser"
import {
  AnalyticsDict,
  AnalyticsEvents,
  AnalyticsProducts,
  AnalyticsProperties,
} from "../types"
import { firebaseAnalytics, isLocalDev } from "../utils"

export const getAnalyticsProperties = (
  product?: AnalyticsProducts,
  numberOfVideos?: number,
  amount?: number,
): AnalyticsDict => {
  return {
    [AnalyticsProperties.product]: product,
    [AnalyticsProperties[AnalyticsProperties.numberOfVideos]]: numberOfVideos,
    [AnalyticsProperties.amount]: `${amount}€`,
  }
}

export const logAnalyticsEvent = (
  eventName: AnalyticsEvents,
  eventProperties?: AnalyticsDict,
) => {
  if (!isLocalDev) {
    mixpanel.track(eventName, eventProperties)
    logEvent(firebaseAnalytics, eventName, eventProperties)
  }
}
