export function getCurrencySymbol(currency?: string): string {
  if (!currency) return ""

  const currencySymbols: { [key: string]: string } = {
    usd: "$", // US Dollar
    eur: "€", // Euro
    gbp: "£", // British Pound
    jpy: "¥", // Japanese Yen
    cny: "¥", // Chinese Yuan
    krw: "₩", // South Korean Won
    inr: "₹", // Indian Rupee
    pkr: "₨", // Pakistani Rupee
    rub: "₽", // Russian Ruble
    try: "₺", // Turkish Lira
    brl: "R$", // Brazilian Real
    aud: "A$", // Australian Dollar
    cad: "C$", // Canadian Dollar
    chf: "CHF", // Swiss Franc
    hkd: "HK$", // Hong Kong Dollar
    sgd: "S$", // Singapore Dollar
    thb: "฿", // Thai Baht
    mxn: "Mex$", // Mexican Peso
    zar: "R", // South African Rand
    sek: "kr", // Swedish Krona
    nok: "kr", // Norwegian Krone
    dkk: "kr", // Danish Krone
    pln: "zł", // Polish Złoty
    ils: "₪", // Israeli Shekel
    aed: "د.إ", // UAE Dirham
    sar: "﷼", // Saudi Riyal
  }

  return currencySymbols[currency.toLowerCase()] || currency.toUpperCase()
}
