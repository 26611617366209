import { Button, CircularProgress, SxProps, Theme } from "@mui/material"
import React from "react"
import { Colors } from "../../types"

export function MainButton({
  onClick,
  text,
  white = false,
  disabled = false,
  loading = false,
  icon,
  type = "button",
  style,
}: {
  onClick?: () => void | Promise<boolean> | Promise<void>
  text: string
  white?: boolean
  disabled?: boolean
  loading?: boolean
  type?: "button" | "submit" | "reset"
  style?: SxProps<Theme>
  icon?: React.ReactNode
}) {
  return (
    <Button
      onClick={onClick ? onClick : () => {}}
      type={type}
      disabled={disabled}
      sx={{
        textTransform: "none",
        height: "3rem",
        borderRadius: "0.5rem",
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "120%",
        px: "1.25rem",
        py: "0.75rem",
        background: white ? Colors.white : Colors.purple900,
        color: white ? Colors.purple900 : Colors.white,
        ":hover": {
          background: white ? Colors.white : Colors.purple900,
          color: white ? Colors.purple900 : Colors.white,
        },
        ":disabled": {
          background: Colors.grey300,
          color: white ? Colors.purple900 : Colors.white,
        },

        boxShadow: "none",
        border: 1,
        ...style,
      }}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {text}
          {icon}
        </>
      )}
    </Button>
  )
}
